import React from "react"

import Layout from "../../components/layout"

const Team = () => (
  <Layout>
    <div>
      <h1>The Team</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa
        voluptatum earum corporis voluptatibus unde quod porro quas ipsam minima
        sint tempora reprehenderit, quidem ad perferendis reiciendis, facere
        incidunt. Quis, quibusdam?
      </p>
    </div>
  </Layout>
)

export default Team
